import { useState } from 'react';
import './App.css';
import axios from 'axios';

axios.defaults.baseURL = 'https://zehshu3k2g.execute-api.us-east-1.amazonaws.com/Production';

function App() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    setError('');
    setSuccess(false);
    setLoading(true);

    let message = {
      name: e.target.name.value,
      email: e.target.email.value,
      subject: e.target.subject.value,
      message: e.target.message.value
    }

    axios.post('/contactMe', message)
      .then(() => setSuccess(true))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }

  return (
    <form
      className="php-email-form mt-4"
      onSubmit={onSubmit}
    >
      {error?.length > 0 && <div className="error-message">{error}</div>}
      {success && <div className="sent-message">Your message has been sent. Thank you!</div>}
      {loading && <div className="loading">Loading...</div>}
      <div className="row mt-4">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="Name"
            required
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Email"
            required
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          placeholder="Subject"
          required
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          rows={5}
          placeholder="Message"
          required
          defaultValue={""}
        />
      </div>
      <div className="text-center mt-5">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
}

export default App;
